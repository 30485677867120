import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/AboutView.vue"),
  // },
  // {
  //   path: "/ContactUs",
  //   name: "ContactUs",
  //   component: () => import("../views/ContactUs.vue"),
  // },
  {
    path: "/",
    name: "DappView",
    component: () => import("../views/DappView.vue"),
  },
  // {
  //   path: "/",
  //   name: "TokenPage",
  //   component: () => import("../views/TokenPage.vue"),
  // },
  // {
  //   path: "/crypto",
  //   name: "CryptoView",
  //   component: () => import("../views/CryptoView.vue"),
  // },
  // {
  //   path: "/cryptowallet",
  //   name: "Crypto Wallet",
  //   component: () => import("../views/CryptoWallet.vue"),
  // },
  // {
  //   path: "/metaverse",
  //   name: "MetaVerse",
  //   component: () => import("../views/MetaVerse.vue"),
  // },
  // {
  //   path: "/blockchain",
  //   name: "BlockChain",
  //   component: () => import("../views/BlockChain.vue"),
  // },
  // {
  //   path: "/smartcontract",
  //   name: "Smart Contract",
  //   component: () => import("../views/SmartContract.vue"),
  // },
  // {
  //   path: "/p2p",
  //   name: "P2P",
  //   component: () => import("../views/P2P.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to, from, savedPosition });
    // if(savedPosition){
    //   return savedPosition
    // }
    return {
      top: 0,
    };
  },
});

export default router;
