<template>
    <div>
        <footer class="mt-5 mt-md-0">
            <div class="container-fluid">
                <div class="row justify-content-between justify-content-lg-start col_non">
                    <div class="col-6 col-sm-6 col-lg-3 bord mb-5 mb-lg-0" v-for="(data_a, index) in data_a" :key="index">
                        <div class="innter_text h-100">
                            <h4 class="quick_lnk mb-4">{{ data_a.quick_link }}</h4>
                            <!-- <img :src="require(`@/assets/footer/${data_a.path}.svg`)" alt="" class="img-fluid"> -->

                            <ul>
                                <li>
                                    <!-- <router-link :to="data_a.link_a" class="active"><span>{{ data_a.dot }}</span>{{ data_a.pagename_a }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_b">{{ data_a.pagename_b }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_c">{{ data_a.pagename_c }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_d">{{ data_a.pagename_d }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_e">{{ data_a.pagename_e }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_f">{{ data_a.pagename_f }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_g">{{ data_a.pagename_g }}</router-link> -->
                                    <!-- <router-link :to="data_a.link_f">{{ data_a.pagename_f }}</router-link> -->





                                    <a :href="data_a.link_a" target="_blank" class="active"> <span>{{ data_a.dot }}</span>{{ data_a.pagename_a }}</a>
                                    <a :href="data_a.link_d" target="_blank">{{ data_a.pagename_d }}</a>
                                    <a :href="data_a.link_b" target="_blank">{{ data_a.pagename_b }}</a>
                                    <a :href="data_a.link_c" target="_blank">{{ data_a.pagename_c }}</a>
                                    <a :href="data_a.link_e" target="_blank">{{ data_a.pagename_e }}</a>
                                    <a :href="data_a.link_f" target="_blank">{{ data_a.pagename_f }}</a>
                                    <a :href="data_a.link_g" target="_blank">{{ data_a.pagename_g }}</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-lg-3 bord mb-5 mb-lg-0" v-for="(data_b, index) in data_b" :key="index">
                        <div class="innter_text h-100">
                            <h4 class="quick_lnk mb-4">{{ data_b.quick_link }}</h4>
                            <!-- <img :src="require(`@/assets/footer/${data_a.path}.svg`)" alt="" class="img-fluid"> -->

                            <ul>
                                <li>
                                    <a :href="data_b.link_a" target="_blank" class="active">
                                        <span>{{ data_b.dot }}</span>{{ data_b.pagename_a }}</a>
                                    <a :href="data_b.link_b" target="_blank">{{ data_b.pagename_b }}</a>
                                    <a :href="data_b.link_c" target="_blank">{{ data_b.pagename_c }}</a>
                                    <a :href="data_b.link_d" target="_blank">{{ data_b.pagename_d }}</a>
                                    <a :href="data_b.link_e" target="_blank">{{ data_b.pagename_e }}</a>
                                    <a :href="data_b.link_f" target="_blank">{{ data_b.pagename_f }}</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row mouse_hover">
                <div class="col-lg-12 text-center">
                    <h1 class="d-lg-none px-5 px-md-0">  <router-Link to="/" class="wdth"> <img src="../assets/footer/footer_logo.png" alt="" class="img-fluid "></router-Link>
                        
                        
                    </h1>
                    <h1 class="d-none d-lg-block">SOLUTIONS</h1>
                    <div class="coursor"></div>
                </div>
            </div>
            <div class="container">
                <div class="row py-2 text-center text-lg-start ">
                    <div class=" col-lg-4 ">
                        <p class="copy_right">All rights reserved</p>
                    </div>
                    <div class=" col-lg-8 text-lg-end ">
                        <p class="copy_right">terms & conditions| privacy policy | privacy policy for VR Games</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name: "CommonFooter",
    components: {

    },

    data() {
        return {
            data_a: [

                {
                    // img: "single_logo",
                    quick_link: " ",
                    pagename_a: "",
                    link_a:"",
                    pagename_b: "",
                    link_b:"",
                    pagename_c: " ",
                    link_c:"",
                    pagename_d: " ",
                    link_d:"",
                    pagename_e: " ",
                    link_e:"",
                    // pagename_f: " ",
                    // link_f:"",
                    pagename_g: " ",
                    link_g:"",
                },

                {
                    quick_link: "Services",

                    pagename_a: "P2P",
                    link_a:"https://lbmsolution.com/p2p",

                    pagename_b: "BlockChain",
                    link_b:"https://lbmsolution.com/blockchain",

                    pagename_c: "Crypto Wallet",
                    link_c:"https://lbmsolution.com/cryptowallet",

                    pagename_d: "About",
                    link_d:"https://lbmsolution.com/about",

                    pagename_e: "Crypto Marketing",
                    link_e:"https://lbmsolution.com/crypto",

                    pagename_f: "Crypto Payment",
                    link_f: "https://lbmsolution.com/cryptopayment",

                    pagename_g: "Smart Contract",
                    link_g: "https://lbmsolution.com/smartcontract",
                },
            ],
            data_b:[
            {
                    quick_link: "Social Media",
                    pagename_a: "Facebook",
                    link_a: "https://www.facebook.com/lbmsolution/",
                    pagename_b: "Twitter",
                    link_b: "https://twitter.com/lbm_solutions",
                    pagename_c: "Instagram",
                    link_c: "https://www.instagram.com/lbmsolutions/",
                    pagename_d: "Telegram",
                    link_d: "https://t.me/lbmsolution",
                    pagename_e: "Youtube",
                    link_e: "https://www.youtube.com/@LBMSolutions",
                    pagename_f: "Linkedin",
                    link_f: "https://www.linkedin.com/company/lbm-solutions/"
                },
                {
                    quick_link: " ",
                    pagename_a: "",
                    link_a:"",
                    pagename_b: "",
                    link_b:"",
                    pagename_c: " ",
                    link_c:"",
                    pagename_d: " ",
                    link_d:"",
                    pagename_e: " ",
                    link_e:"",
                    pagename_f: " ",
                    link_f:"",
                },
            ]
        }
    }
}
</script>


<style scoped lang="scss">


footer {
    overflow-x: hidden;

    p {
        font-size: 18px;
        text-transform: capitalize;
    }

    .bord:first-child {
        .innter_text {


            &:before {
                content: "";
                /* border-left: 0; */
                background-image: url('../assets/footer/single_logo.png');
                background-repeat: no-repeat;
                height: 263px;
                width: 200px;
                position: absolute;
                // top: 21%;
                // left: 20%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .bord:nth-child(2) {
        .innter_text {
            &:before {
                content: "L";
                position: absolute;
                top: -28%;
                height: 263px;
                color: #141414;
                font-weight: 700;
                font-size: 370px;
                left: 0%;
                // transform: translate(-50%, -50%);
                z-index: -2;
                // border-right:1px solid red ;

            }

            &:after {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                border-radius: 1px solid red;
                bottom: 0;
                left: 0;
                background-color: #141414;
            }
        }
    }

    .bord:nth-child(3) {
        .innter_text {
            &:before {
                content: "B";
                position: absolute;
                top: -28%;
                height: 263px;
                color: #141414;
                font-weight: 700;
                font-size: 370px;
                left: 0%;
                // transform: translate(-50%, -50%);
                z-index: -2;

            }

            &:after {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                border-radius: 1px solid red;
                bottom: 0;
                left: 0;
                background-color: #141414;
            }
        }
    }

    .bord:nth-child(4) {
        .innter_text {
            &:before {
                content: "M";
                position: absolute;
                top: -28%;
                height: 263px;
                color: #141414;
                font-weight: 700;
                font-size: 370px;
                left: 0%;
                // transform: translate(-50%, -50%);
                z-index: -2;
            }

            &:after {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                border-radius: 1px solid red;
                bottom: 0;
                left: 0;
                background-color: #141414;
            }
        }
    }




    h1 {
        letter-spacing: 27px;
        font-size: 160px;
        font-weight: 700;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .innter_text {
        // border: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 0;
        border-bottom: 0;
        position: relative;
        padding: 20px 0 20px 270px;

        h4 {
            color: var(--yellowgreen);
        }

        .quick_lnk {
            font-size: 24px;
            font-weight: 600;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                a {
                    font-size: 18px;
                    padding: 10px 0;

                    display: block;
                    color: var(--white) !important;
                    display: flex;
                    align-items: center;
                    transition: 0.5s all;

                    &:hover {
                        color: var(--yellowgreen) !important;
                    }

                }
            }
        }
    }
}



















@media(min-width:320px)and (max-width:420px) {

    .col-6 {
        width: 100%;
    }
}

@media(min-width:320px)and (max-width:575px) {
    footer {

        .col_non .col-6:first-child,
        .col_non .col-6:last-child {
            display: none;
        }


        h1 {
            font-size: 37px;
            padding: 10px 0;
        }

        .innter_text {
            padding: 0px;
            border: 0;

            .quick_lnk {
                font-size: 18px;
            }
        }

    }



}


@media(min-width:576px)and (max-width:767px) {
    footer {
        h1 {
            font-size: 50px;
            padding: 20px 0;
        }

        .innter_text {
            padding: 0 50px;
            border: 0;
        }


        .col_non .col-6:first-child,
        .col_non .col-6:last-child {
            display: none;
        }


    }
}

@media(min-width:768px)and (max-width:991px) {
    footer {
        h1 {

            font-size: 66px;
            padding: 20px 0;
        }

        .innter_text {
            padding: 0 96px;
            border: 0;
        }


        .col_non .col-6:first-child,
        .col_non .col-6:last-child {
            display: none;
        }
    }
}

@media(min-width:992px)and (max-width:1199px) {
    footer {

        h1 {
            letter-spacing: 7px;
            font-size: 130px;
        }

        .innter_text {
            padding: 0 0px 20px 50px;
            border: 0;
        }

        // .col_non .col-6:first-child,
        // .col_non .col-6:last-child {
        //     display: none;
        // }
    }

    .bord:nth-child(2),
    .bord:nth-child(3),
    .bord:nth-child(4) {
        .innter_text::before {
            top: 2% !important;
            font-size: 240px !important;
        }
    }
}


@media(min-width:1200px) and (max-width:1399px) {
    footer {
        .innter_text {
            padding: 0 0px 20px 50px;
            // border: 0;
        }
    }
}


@media(min-width:1400px) and (max-width:1600px) {
    footer {
        .innter_text {
            padding: 0 0px 20px 100px;
            // border: 0;
        }



        .bord:first-child {
            .innter_text {


                &:before {
                    height: 261px;
                    width: 182px;
                    top: 0%;
                    left: 20%;
                }
            }
        }


        .bord:nth-child(2) {
            .innter_text {
                &:before {
                    top: -31%;
                    font-size: 310px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    border-radius: 1px solid red;
                    bottom: 0;
                    left: 0;
                    background-color: #141414;
                }
            }
        }

        .bord:nth-child(2) {
            .innter_text {
                &:before {
                    top: -31%;
                    font-size: 310px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    border-radius: 1px solid red;
                    bottom: 0;
                    left: 0;
                    background-color: #141414;
                }
            }
        }

        .bord:nth-child(4) {
            .innter_text {
                &:before {
                    top: -31%;
                    font-size: 310px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    border-radius: 1px solid red;
                    bottom: 0;
                    left: 0;
                    background-color: #141414;
                }
            }
        }
    }
}




@media(min-width:1601px) and (max-width:1800px) {
    footer .innter_text {

        padding: 20px 0 20px 200px;
    }
}



@media(max-width:991px) {


    footer {
        h1 {
            letter-spacing: 4px;
        }
    }

    .bord:first-child {
        .innter_text {


            &:before {
                content: unset !important;
            }
        }
    }

    .bord:nth-child(2) {
        .innter_text {
            &:before {
                content: unset !important;

            }

            &:after {
                content: unset !important;
            }
        }
    }

    .bord:nth-child(3) {
        .innter_text {
            &:before {
                content: unset !important;

            }

            &:after {
                content: unset !important;
            }
        }
    }

    .bord:nth-child(4) {
        .innter_text {
            &:before {
                content: unset !important;

            }

            &:after {
                content: unset !important;
            }
        }
    }
}
</style>