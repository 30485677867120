<template>
  <!-- <MainHeader /> -->
  <router-view />
  <!-- <MainFooter /> -->
  <CommonFooter />
  <WhatsApp />


  <!-- <LoaderFile /> -->
</template>



<script>
// import LoaderFile from "@/components/LoaderFile.vue"
// import MainHeader from "@/components/MainHeader.vue"
import CommonFooter from '@/components/CommonFooter.vue';
import WhatsApp from '@/components/WhatsApp.vue';
// import MainFooter from "@/components/MainFooter.vue"

export default {
  name: "App",
  components: {
    // LoaderFile,
    // MainHeader,
    // MainFooter,
    CommonFooter,
    WhatsApp

  },
  data() {
    return {
      show: false,
    }
  },
  async mounted() {


    var Tawk_API = Tawk_API || {};
    // let Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5b6bf0a6e21878736ba2c411/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();

    await new Promise(r => setTimeout(r, 200));
    this.show = true;


  }
}

</script>



<style>
:root {
  --blue: #6BBBFF;
  --white: #fff;
  --black: #000;
  --yellowgreen: #15e49e;
  --gray: #262626;


  --font-family-inter: 'Inter', sans-serif;
  --font-family-noto: 'Noto Serif KR', serif;
  --font-family-dm-sans: DM Sans, sans-serif;
  --font-family-Caveat: "Caveat", cursive;

}



html,
body {
  overflow-x: hidden;
}


.bg_gray {
  background-color: var(--gray);
}

a,
li {
  text-decoration: none !important;
  list-style: none !important;
}

body {
  background-color: var(--black) !important;
  color: var(--white) !important;
  /* font-family: 'Inter', sans-serif !important; */
  font-family: var(--font-family-inter) !important;
}

section {
  padding: 60px 0;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 4px !important;
}

/* .swiper-pagination {
	position:unset !important;
} */

.parent-div .swiper-button-next,
.parent-div .swiper-button-prev {
  display: none !important;
}

.my-class {
  background-color: var(--white) !important;
}




/* accordion css */
section.faq .accordion-button:focus {
  box-shadow: unset !important;
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour,
div#collapseFive {
  background-color: #1d1b1b;
  border-radius: 0 0 15px 15px;
}

section.faq .accordion-collapse p {
  font-size: 14px !important;
}

section.faq .accordion-item {
  color: var(--bs-accordion-color);

}

.accordion {
  position: relative;
}

.accordion:before {
  content: "";
  height: 96%;
  width: 2px;
  background: #464343;
  position: absolute;
  left: -27px;
}

div#collapseOne {
  position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after, 
div#collapseFive:after {
  content: "";
  height: 156%;
  width: 1px;
  background: #fff;
  position: absolute;
  left: -27px;
  top: -57px;
}

div#collapseTwo:after {
  height: 36%;
  top: 79px;
}

div#collapseThree:after {
  height: 37%;
  top: 136px;
}

div#collapseFour:after {
  height: 41%;
  top: 205px;
}
div#collapseFive:after {
  height: 41%;
  top: 205px;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed,
section.faq .accordion-button:not(.collapsed) {
  border: unset !important;
  color: #fff !important;
  border-radius: 15px 15px 0 0;
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #000;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed {
  background-color: #0e0c0c;
}

section.faq .accordion-button::after {
  filter: invert(0.5);
  padding: 10px 10px;
  border: 1px solid #616161;
}

/* accordion css */


/* crypto css start rh */

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons !important;
  font-size: 12px !important;
  bottom: -250px;
  position: absolute;
  color: var(--white);
  padding: 12px 17px;
  border-radius: 4px;
  background: #414141;
}

.swiper-button-prev:after {
  content: 'prev';
  position: absolute;
  right: -1190px;
}






/* _____________  jy   27/10/23 start__________________ */


.main-div .swiper-pagination-bullet {
  background-color: #15e49e !important;
}

/* _____________  jy   27/10/23 end__________________ */


/* _____________  jy   27/10/31 start__________________ */
.meta_slide .swiper-button-prev:after,
.meta_slide .swiper-button-next:after {
  font-family: swiper-icons !important;
  font-size: 12px !important;
  bottom: -160px !important;
  position: absolute !important;
  color: #fff !important;
  padding: 12px 17px !important;
  border-radius: 4px !important;
  background: #9C9C9C !important;
}

.meta_slide .swiper-button-next:after {
  background-color: #0C0C0C !important;
}

.meta_slide .swiper-wrapper {
  min-height: 400px !important;
}

.meta_slide .swiper-button-next:after {
  right: 0;
}


@media (min-width:320px) and (max-width:575px) {

  .meta_slide .swiper-button-prev:after {
    right: -100px;
  }

  .meta_slide .swiper-button-next:after {
    right: 100px;
  }
}

@media (min-width:576px) and (max-width:767px) {

  .meta_slide .swiper-button-prev:after {
    right: -210px;
  }

  .meta_slide .swiper-button-next:after {
    right: 210px;
  }
}

@media (min-width:768px) and (max-width:991px) {

  .meta_slide .swiper-button-prev:after {
    right: -275px;
  }

  .meta_slide .swiper-button-next:after {
    right: 275px;
  }
}

@media (min-width:992px) and (max-width:1199px) {

  .meta_slide .swiper-button-prev:after {
    right: -830px;
  }

  .meta_slide .swiper-button-next:after {
    right: 0px;
  }
}

@media (min-width:1200px) and (max-width:1399px) {

  .meta_slide .swiper-button-prev:after {
    right: -999px;
  }

  .meta_slide .swiper-button-next:after {
    right: 0px;
  }
}

@media (min-width:1400px) and (max-width:1900px) {

  .meta_slide .swiper-button-next:after {
    right: 0;
  }
}

.meta_slide .swiper-pagination-bullet {
  display: none !important;
}

/* _____________  jy   27/10/21 end__________________ */


/* _____________  jy   27/11/1 start_________________ */

@media (min-width:320px) and (max-width:1900px) {

  .p2p_slide .swiper-button-prev:after,
  .p2p_slide .swiper-button-next:after {
    display: none;
  }
}

/* _____________  jy   27/11/1 end__________________ */






@media all and (min-width:1200px) and (max-width:1399px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:992px) and (max-width:1199px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:786px) and (max-width:991px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }
}

@media all and (min-width:320px) and (max-width:767px) {

  .parent-div .swiper-button-prev:after,
  .parent-div .swiper-button-next:after {
    display: none;
  }

  section {
    padding: 40px 0;
  }

}

/* crypto css end rh */
</style>


